// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.scss"

export const onRouteUpdate = ({ location }) => {
    document.body.classList.remove("home")
    // Check if the current path is the home page
    if (location.pathname === "/") {
      // Add the "home" class to the body tag
      document.body.classList.add("home")
    }
  }
  